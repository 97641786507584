import React from "react"
import styled from "styled-components"
import Mailing from "../Shared/mailing"
import { motion } from "framer-motion"
import Countdown from "react-countdown"

const Launch = () => {
    const variants = {
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 20 },
      }
    return (
        <motion.div
            initial="hidden"
            animate="visible"
            variants={variants}
            transition={{ ease: "easeOut", duration: 0.8, delay: 1.2 }}
        >
            <BannerWrapper>
                <div className="flex-min-height-100vh pos-rel">
                    <div className="container small padding-top-bottom-120 text-color-black pos-rel">
                        <h2 className="headline-xxxxl">
                            <span className="hidden-box d-block heading">
                                CHURCH LAUNCH COUNTDOWN
                            </span>
                        </h2>
                        <div className="pos-rel">
                            <div className="count-div">
                                <Countdown date={Date.parse("2022-04-17")}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="connected text-color-black">
                    <h3 className="subhead-m margin-bottom-30">For more information about our church launch and fundraising goals over the next several months, be sure to visit our Launch Page and connect with us on our socials.</h3>
                    <a target="_blank" rel="noreferrer" aria-label="Launch Page" href="https://amplified-church.raisely.com" className="button">Launch Page</a>
                </div>
                <Mailing />
            </BannerWrapper>
        </motion.div>
    )
}

const BannerWrapper = styled.div`
.count-div{
    font-size: 8rem;
    justify-content: center;
    color: white;
    background-color: black;
    border-radius: 25px;
    text-align: center;
    top: 0;
    left: 50%;
    margin-top: 15%;
    @media only screen and (max-width: 767px){
        font-size: 2rem;
      }
}
.heading{
    z-index: 1;
    text-align: center;
    position: relative;
}
.front-graphic{
    z-index: 0;
    margin-top: 3rem;
    height: auto;
    width: 100%;
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.connected{
    padding: 5% 25%;
    text-align: center;
    @media only screen and (max-width: 767px){
        padding 5% 10%;
        margin-bottom: 50%;
      }
}
`
export default Launch