import * as React from "react"
import Layout from "../components/layout"
import Launch from "../components/ChurchLaunch/launch"

const ChurchLaunch = () => {
  return (
    <Layout>
    <Launch />
    </Layout>
  )
}

export default ChurchLaunch